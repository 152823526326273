import React, { useState } from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import "./OnlinePayments.scss"
import axios from "axios";
import Loader from "react-loader-spinner";
import "../Competitions/Competitions.scss";
import SetupForm from "./SetupForm";
import PersonalDataForm from "./PersonalDataForm";
import * as keys from "./../../../credentials/keys.json";

export default function OnlinePayments(props) {
    const { NODE_ENV } = process.env;

    const stripePromise = loadStripe(NODE_ENV == "development" ? keys.stripe_pk_test : keys.stripe_pk_live, {
        locale: `${props.lang}` === 'ro' ? 'ro' : 'en'
    });

    const [showFirstStep, setShowFirstStep] = useState(true);
    const [showSecondStep, setShowSecondStep] = useState(false);
    const [loaded, setLoaded] = useState(false);
    const [options, setOptions] = useState({});
    const [redirectParams, setRedirectParams] = useState("");

    function toSecondStep() {
        setShowFirstStep(false);
        setShowSecondStep(true);
        setTimeout(() => {
            setLoaded(true);
        }, 2500);
    }

    function backToFirstStep() {
        setShowFirstStep(true);
        setShowSecondStep(false);
        setLoaded(false);
    }

    function pull_data(data) {
        let payload = {
            name: data.name,
            email: data.email,
            id_club: props.id_club,
            club_name: props.club_name,
            id_team: data.id_team?.value,
            team_name: data.id_team?.label,
            id_child: data.id_child,
            payment_type: data.payment_type,
            payment_amount: data.payment_amount,
            recurrent_payment: {
                true: "1",
                false: "0"
            }[data.recurrent_payment],
            description: data.description,
            day_of_pay: data.day_of_pay,
            payment_month: data.payment_month,
            payment_year: data.payment_year,
        };

        if (NODE_ENV == "development") {
            payload.test = 1;
        }

        if (data.payment_type_obj?.type == "payment_request") {
            payload.payment_request_data = data.payment_type_obj;
            setRedirectParams("type=payment_request");
        }

        axios
            .post("payment/generate_payment_intent_3", payload)
            .then((res) => {
                setOptions({
                    clientSecret:
                        res.data,
                    appearance: {
                        /*...*/
                    }
                });
                toSecondStep();
            })
            .catch((err) => console.log(err));
    }

    return (
        <div className={`onlinepayments-page-container${props.onlyIframe == 1 ? " no-margin-top no-padding-bottom" : ""}`} style={{ color: props.config_options.contact_color }}>
            <div className="onlinepayments-title" >
                Plată online
            </div>
            <PersonalDataForm
                onClick={toSecondStep}
                dataAuth={pull_data}
                toShow={showFirstStep}
                id_club={props.id_club}
                config_options={props.config_options}
                lang={props.lang}
                feePassedToCustomer={props.feePassedToCustomer}
                editableMonthlyTax={props.editableMonthlyTax}
            />
            {showSecondStep &&
                <div>
                    <div className="loader-container" style={{ display: !loaded ? "block" : "none" }}>
                        <Loader type="ThreeDots" color={props.config_options.main_color} height={80} width={80} lang={props.lang} />
                    </div>
                    <div>
                        <Elements stripe={stripePromise} options={options} >
                            <SetupForm
                                clientSecret={options.clientSecret}
                                onClickBack={backToFirstStep}
                                toShow={loaded}
                                config_options={props.config_options}
                                lang={props.lang}
                                urlRedirect={props.urlRedirectPayment}
                                redirectParams={redirectParams}
                            />
                        </Elements>
                    </div>
                </div>
            }
        </div>
    );
}
